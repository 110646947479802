<template>
  <div>
    <z-select
      v-model="interval"
      style="max-height: 240px !important"
      :hideDetails="true"
      label="Selecione o intervalo"
      itemValue="value"
      :items="intervalList"
      itemText="name"
      @change="
        $emit('select-interval', $event);
        interval == 6 ? '' : (interval = '');
      "
    >
      <template #item="{ item }">
        <section class="d-flex align-center justify-space-between" style="width: 100%">
          <strong> {{ item.name }} </strong>
          <small> {{ item.textDate }} </small>
        </section>
      </template>
    </z-select>

    <section v-if="interval === 6" style="height: 40px" class="d-flex align-center mt-5">
      <DatePicker
        v-model="customInterval"
        range
        value-type="YYYY-MM-DD"
        format="DD/MM/YYYY"
        style="width: 100% !important"
        @change="
          $emit('select-custom-interval', 6, $event);
          customInterval = null;
        "
      />
    </section>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
export default {
  inject: ["intervalList"],
  data() {
    return {
      interval: null,
      customInterval: null,
    };
  },
  components: {
    DatePicker,
  },

  watch: {
    interval: {
      handler(nv) {
        if (nv == 6) this.$emit("block-close-on-click", false);
        else {
          this.$emit("block-close-on-click", true);
          this.customInterval = "";
        }
      },
      deep: true,
    },
  },
};
</script>
